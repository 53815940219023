import React from 'react'

import LoginForm from '../components/loginComponent/LoginForm.js'


export default function Login() {

  return (
    <div>
      <LoginForm />
    </div>
  )
}
