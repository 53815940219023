import React from 'react'
import RegisterForm from '../components/registerComponent/RegisterForm.js'

function Register() {

  return (
    <div>
      <RegisterForm />
    </div>
  )
}

export default Register